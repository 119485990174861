import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const OurExperience = () => {
  return (
    <section className="my-20">
      <p className="underline-title my-5 ">OUR EXPERIENCE</p>
      <h2 className="font-bold text-lg uppercase text-center my-5">
        OVER 150 YEARS OF COMBINED WATCHMAKING AND JEWELRY/CASE AND BRACELET REPAIR EXPERIENCE!
      </h2>
      <div className="flex flex-col xl:flex-row gap-5">
        <StaticImage
          alt="Miami Watch Repairs by Gray and Sons Jewelers"
          src={'../../images/watch-repair-banner2.jpg'}
          loading="lazy"
          className="aspect-video w-10/12 mx-auto xl:aspect-square xl:w-2/5 "
        />
        <div className="xl:w-3/5">
          <h3 className="font-bold uppercase">
            How to Repair Your Watch with Gray & Sons Jewelers
          </h3>
          <p>
            We offer our watch repair services locally in South Florida (Miami, Miami Beach,
            Surfside, Bal Harbour, Fort Lauderdale, and West Palm Beach), nationally as well as
            internationally with our Easy Ship Box Option.
          </p>
          <br/>
          <br/>
          <h3 className="font-bold uppercase">Local Repairs</h3>
          <p>
            Come visit our showroom and let us evaluate your watch. Our Swiss-trained watchmakers
            are among the best in the United States and we just happen to be located on Miami Beach.
            We will provide you an estimate before beginning any work.
          </p>
          <br/>
          <br/>
          <h3 className="font-bold uppercase">National Repairs</h3>
          <p>
            To accommodate customers across the United States who complete repairs with us, we
            devised the Easy Ship Box as the fastest and most convenient way to send sensitive
            luxury items. This allows you to receive our state of the art services anywhere in the
            country, so you never compromise on the care your watch rightfully deserves.
          </p>
        </div>
      </div>
    </section>
  )
}

export default OurExperience
